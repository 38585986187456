import React, { Component } from 'react';
import get from 'lodash/get';
import breakpoint from 'styled-components-breakpoint';
import styled, { withTheme } from 'styled-components';
import { Row, Col, Modal } from 'antd';
import {
  Title,
  Paragraph,
  SectionContainer,
  Image,
  Link,
} from '../common/components';
import scrollToComponent from 'react-scroll-to-component';
import RequestLimousineForm from '../common/components/RequestLimousineForm';

const Container = styled(SectionContainer)`
  position: relative;
  min-height: 250px;
  margin-bottom: 70px !important;
  margin-top: 0px !important;
  padding-bottom: 0;
  padding: 0 5%;

  ${breakpoint('md')`
    padding-bottom: 30px;
  `}
`;

const TitleText = styled(Title)`
  text-align: left;
  text-transform: uppercase;
`;

const ImageCol = styled(Col)`
  overflow: hidden;
`;

const Img = styled(Image)``;

const ParagraphContext = styled(Paragraph)`
  text-align: left;
`;

const DescriptionCol = styled(Col)`
  padding: 20px 0 !important;

  ${breakpoint('md')`
    padding-left: 20px !important;
  `}

  ${breakpoint('lg')`
      padding: 0 20px !important;
      padding-left: 100px !important;
  `}
`;

const DescriptionReadMore = styled(Link)`
  font-size: 16px;
  text-align: left;
  font-weight: bold;
`;

const FormInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.disabled
      ? props.theme.color.background.black
      : props.theme.color.background.main};
  height: 50px;
  overflow: hidden;
  width: 100%;
  margin-top: 15px;
  margin-right: 15px;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  * {
    font-family: ${props => props.theme.fontFamily};
  }
`;

const FormInputContainerWithoutPadding = styled(FormInputContainer)`
  padding: 0px !important;
`;

const FormInputContainerWithoutBG = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  overflow: hidden;
  width: 100%;
  padding: 5px;
  margin-top: 15px;
  margin-right: 15px;
  justify-content: space-between;
  * {
    font-family: ${props => props.theme.fontFamily};
  }
`;

interface Props {
  data: any;
  theme: any;
  anchorHash?: string;
  form: any;
}

class ServiceLimousine extends Component<Props, any> {
  limousine: any;

  constructor(props) {
    super(props);

    this.state = {
      showBooking: false,
    };

    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    if (this.props.anchorHash && this.props.anchorHash === '#limousine') {
      try {
        setTimeout(() => {
          scrollToComponent(this.limousine, {
            offset: -50,
            align: 'top',
            duration: 2000,
            ease: 'linear',
          });
        }, 500);
      } catch (e) {}
    }
  }

  onClose() {
    this.setState({ showBooking: false });
  }

  render() {
    const { data, theme } = this.props;
    const title = get(data, 'primary.limousineTitle', '');
    const description = get(data, 'primary.limousineDescription.text', '');
    const descriptionHtml = get(data, 'primary.limousineDescription.html', '');
    const image = get(data, 'primary.limousineImage');

    const { showBooking } = this.state;

    return (
      <div ref={ref => (this.limousine = ref)}>
        <Container>
          <Row type="flex" justify="center" align="middle">
            {/* Name Service Limousine */}
            <Col xs={24} md={0}>
              {title && <TitleText>{title}</TitleText>}
            </Col>
            {/* Context */}
            <DescriptionCol xs={24} md={11}>
              <Row type="flex" justify="start">
                <Col xs={0} md={24}>
                  {title && (
                    <TitleText level={2} size={1}>
                      {title}
                    </TitleText>
                  )}
                </Col>
                <Col>
                  {descriptionHtml && (
                    <ParagraphContext small>
                      <span
                        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                      />
                    </ParagraphContext>
                  )}
                </Col>
                <DescriptionReadMore
                  onClick={() => this.setState({ showBooking: true })}
                >
                  {get(data, 'primary.limousineReadmoreText', '')}
                </DescriptionReadMore>
              </Row>
            </DescriptionCol>
            {/* Golf Image */}
            <ImageCol xs={24} md={{ span: 11, offset: 2 }}>
              <Img data={image} width="100%" />
            </ImageCol>
          </Row>
        </Container>

        {/* POP UP MODAL FOR BOOKING */}
        <RequestLimousineForm
          data={data}
          showBooking={showBooking}
          onClose={this.onClose}
        />
      </div>
    );
  }
}

export default withTheme(ServiceLimousine);
