import React, { Component } from 'react';
import get from 'lodash/get';
import { Introduction } from '../common/components';

interface Props {
  data: any;
}
class ServiceIntroduction extends Component<Props, any> {
  render() {
    const { data } = this.props;
    const title = get(data, 'primary.title', '');
    const description = get(data, 'primary.description', '');
    return <Introduction title={title} description={description} />;
  }
}

export default ServiceIntroduction;
