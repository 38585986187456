import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import { PageHero } from '../common/components/';
import ServiceIntroduction from './ServiceIntroduction';
import ServiceShop from './ServiceShop';
import ServiceLimousine from './ServiceLimousine';

interface Props {
  data: any;
  location: any;
}

class Service extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');
    const limousineAnchorHash = get(this.props, 'location.hash', null);

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body) &&
          body.map(slice => {
            switch (slice.__typename) {
              case 'PrismicServiceBodyHero':
                return (
                  <PageHero key={slice.id + slice.__typename} data={slice} />
                );
              case 'PrismicServiceBodyIntroduction':
                return (
                  <ServiceIntroduction
                    key={slice.id + slice.__typename}
                    data={slice}
                  />
                );
              case 'PrismicServiceBodyShop':
                return (
                  <ServiceShop key={slice.id + slice.__typename} data={slice} />
                );
              case 'PrismicServiceBodyLimousine':
                return (
                  <ServiceLimousine key={slice.id + slice.__typename} data={slice} anchorHash={limousineAnchorHash}/>
                );
              default:
                return null;
            }
          })}
      </Layout>
    );
  }
}

export default Service;

// Query metadata for the homepage
export const query = graphql`
  query ServiceQuery($slug: String!) {
    page: prismicService(id: { eq: $slug }) {
      ...Service
    }
  }
`;
