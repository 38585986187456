import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import get from 'lodash/get';
import { Row, Col } from 'antd';
import {
  Title,
  Spacer,
  FlowerPattern,
  Card,
  ItemsSlider,
  Paragraph,
  Link,
} from '../common/components';
import { theme } from '../common/theme';
import flowerGold1 from '../assets/flower-3-gold.svg';
import flowerGold2 from '../assets/flower-2-gold.svg';
import { getInternalUrl } from '../utils';

const ShopItemTitle = styled(Title)`
  font-weight: normal !important;
  margin-bottom: 0 !important;
  text-align: center;
`;

const ShopCard = styled(Row)`
  margin: 0 10px;
  /* height: 320px; */

  ${breakpoint('md')`
    margin-left: 10px;
    margin-right: 10px;
    height: 390px;
  `}
`;

const ShopCardContent = styled(Card.Content)`
  /* padding: 20px;

  ${breakpoint('md')`
    padding: 30px;
  `} */
`;
const LogoImage = styled(Card.CoverImage)`
  /* height: 100px; */
`;
const LogoImageContainer = styled(Card.CoverImageContainer)`
  height: 80%;
`;

const Container = styled.div`
  position: relative;
  min-height: 140px;
  margin-top: 50px;
  margin-bottom: 20px;
  padding-left: 3%;
  padding-right: 3%;
  bottom: 0;

  ${breakpoint('md')`
    margin-bottom: 50px;
  `}
`;

const ShopTitle = styled(Title)`
  font-weight: normal !important;
  margin-bottom: 0 !important;
  text-align: center;
`;

const ShopSpacer = styled(Spacer)`
  margin: 30px auto !important;
`;

const DescriptionCol = styled(Col)`
  text-align: center;
  display: flex !important;
  justify-content: center;
`;

const DescriptionTextContainer = styled.div`
  max-width: 900px;
  ${breakpoint('xs')`
    padding:0 30px;
  `}
  ${breakpoint('md')`
    padding:0 60px;
  `}
`;

const DescriptionText = styled(Paragraph)`
  color: ${props => props.theme.color.text.main} !important;
  text-align: center;
`;

// Own shops item component
const ShopItem = ({ title, image, url, lang }) => (
  <Link to={getInternalUrl(url, lang)}>
    <ShopCard type="flex" color="transparent">
      {image && (
        <LogoImageContainer xs={24}>
          <LogoImage
            fluid={get(image, 'localFile.childImageSharp.fluid')}
            alt={get(image, 'alt')}
            imgStyle={{
              'object-position': 'center',
              'object-fit': 'contain',
            }}
          />
        </LogoImageContainer>
      )}
      <Card.ContentContainer xs={24}>
        <ShopCardContent type="flex" justify="space-between">
          {title && (
            <ShopItemTitle level={2} color={theme.color.text.main}>
              {title}
            </ShopItemTitle>
          )}
        </ShopCardContent>
      </Card.ContentContainer>
    </ShopCard>
  </Link>
);
const ShopItemWithoutUrl = ({ title, image, lang }) => (
  <ShopCard type="flex" color="transparent">
    {image && (
      <LogoImageContainer xs={24}>
        <LogoImage
          fluid={get(image, 'localFile.childImageSharp.fluid')}
          alt={get(image, 'alt')}
          imgStyle={{
            'object-position': 'center',
            'object-fit': 'contain',
          }}
        />
      </LogoImageContainer>
    )}
    <Card.ContentContainer xs={24}>
      <ShopCardContent type="flex" justify="space-between">
        {title && (
          <ShopItemTitle level={2} color={theme.color.text.main}>
            {title}
          </ShopItemTitle>
        )}
      </ShopCardContent>
    </Card.ContentContainer>
  </ShopCard>
);

interface Props {
  data: any;
}

const CreateShopItems = (items: any) => {
  const shopsItem = items.map((item, i) => {
    const document = get(item, 'shopItemCategory.document[0]');
    if (!document) return null;

    const id = get(document, 'id', i);
    const lang = get(document, 'lang');
    const title = get(document, 'data.title', '');
    const url = get(document, 'data.url', '');
    const featureImage = get(document, 'data.featureImage');
    if (!url || url === '') {
      return (
        <ShopItemWithoutUrl
          key={id}
          title={title}
          image={featureImage}
          lang={lang}
        />
      );
    }
    return (
      <ShopItem
        key={id}
        title={title}
        image={featureImage}
        url={url}
        lang={lang}
      />
    );
  });

  return shopsItem;
};

class ServiceShop extends React.Component<Props, any> {
  render() {
    const { data = null } = this.props;
    if (!data) return null;

    const { items = [], primary = null } = data;
    const shopTitle = get(primary, 'shopTitle', '');
    const shopDescription = get(primary, 'shopDescription', '');

    return (
      <Container>
        <FlowerPattern
          src={flowerGold1}
          height="100px"
          width="auto"
          right="96%"
          bottom="0"
          opacity={0.6}
        />
        <FlowerPattern
          src={flowerGold2}
          height="140px"
          width="auto"
          right="10px"
          bottom="80%"
          opacity={0.6}
        />

        {shopTitle && (
          <Row type="flex" justify="center" align="top">
            <Col span={24}>
              <ShopTitle level={1} size={2} color={theme.color.primary.light}>
                {shopTitle.toUpperCase()}
              </ShopTitle>
            </Col>
            <Col span={24}>
              <ShopSpacer color="#787760" />
            </Col>
            {shopDescription && (
              <DescriptionCol xs={24}>
                <DescriptionTextContainer>
                  <DescriptionText>{shopDescription}</DescriptionText>
                </DescriptionTextContainer>
              </DescriptionCol>
            )}
          </Row>
        )}
        {items.length > 0 && (
          <Row>
            <Col span={24}>
              <ItemsSlider
                sliderItems={CreateShopItems(items)}
                settings={{
                  autoplay: false,
                  slidesToShow: 3,
                  infinite: items.length > 3,
                  responsive: [
                    {
                      // Desktop breakpoint
                      breakpoint: theme.breakpoints.xl,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                    {
                      // Tablet breakpoint
                      breakpoint: theme.breakpoints.md,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      // Mobile breakpoint
                      breakpoint: theme.breakpoints.sm,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ],
                }}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default ServiceShop;
